import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import "./index.scss";

import Locations from "./modules/locations/";
import Content from "./modules/content/";
import Meta from "./modules/meta/";
import Linklist from "./modules/linklist/";
import VideoThing from "videoThing";

UIkit.use(Icons);
UIkit.switcher(document.querySelectorAll(".uk-switcher"));

class DigitalFair {
  constructor() {
    this.currentLanguage = null;
    this.location = null;
  }

  init() {

    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      $('body').addClass('rotate');
    }

    this.setCurrentLanguage();
    this.locations = new Locations();
    this.content = new Content(this.currentLanguage);
    this.meta = new Meta();
    this.linklist = new Linklist();
    this.locations.getAll(this.currentLanguage).then(locations => {
      this.locationsData = locations;
      this.videoThing = this.initVideoThing(locations);
    });

    window.onload = () => {
      this.buildMetaMenu();
      this.buildLinklistMenu();
    };

    setTimeout(() => {
      this.buildLocationsMenu();
      this.buildContentsMenu();
      document.querySelector(".loader").classList.add("loader--hidden");
    }, 500);
  }

  initVideoThing(locations) {
    const vt_target = document.querySelector(".app__vt");
    const vt_settings = {
      assetPath: "./static/videos",
      startLocation: "A0",
      preloadDestinationsAtStart: false,
      screensaver: {
        timeout: 20,
        cue: ["A1", "A2", "A3", "A0"],
        locationTime: 8
      },
      buttons: {
        staggerVal: 200,
        transitionDuration: 400
      },
      controls: {
        show: true,
        transitionDuration: 800
      },
      transitionOverlay: {
        color: "#ffffff",
        transitionDuration: 1000
      }
    };

    const vt = new VideoThing(vt_target, locations, vt_settings);

    vt.on("button_clicked", id => {
      console.info("You clicked button: " + id);
      this.content.show(id);
    });

    vt.on("location_reached", id => {
      var title = this.locationsData.find(entry => entry.id === id).title;
      var $title = document.querySelector(".js-title");

      $title.innerText = title;
      $title.classList.remove("hidden");

      console.info("You reached location: " + id + " (" + title + ")");
    });

    vt.on("location_left", id => {
      console.info("You left location: " + id);
      document.querySelector(".js-title").classList.add("hidden");
    });

    return vt;
  }

  buildMetaMenu() {
    this.meta.getMenu().then(elem => {
      document.querySelector(".app__footer").appendChild(elem);
    });
  }

  buildLinklistMenu() {
    this.linklist.getMenu().then(elem => {
      document.querySelector(".linklist").appendChild(elem);
    });
  }


  buildContentsMenu() {

    this.content.getMenu().then(elem => {
      if (window.innerWidth <= 960) {
        console.log(elem);
        document.querySelector(".js-mobile-menus .schnellzugriff").appendChild(elem);
      } else {
        document.querySelector(".menus").appendChild(elem);
      }

      elem.querySelectorAll("[data-content]").forEach(elem => {
        elem.addEventListener("click", () => {
          let contentId = elem.dataset.content;
          this.content.show(contentId);
        });
      });
    });


  }

  buildLocationsMenu() {
    this.locations.getMenu().then(elem => {
      console.log(elem);
      if (window.innerWidth <= 960) {
        console.log(elem);
        document.querySelector(".js-mobile-menus .themen").appendChild(elem);
      } else {
        document.querySelector(".menus").appendChild(elem);
      }
      elem.querySelectorAll("[data-location]").forEach(elem => {
        elem.addEventListener("click", () => {
          let locationId = elem.dataset.location;
          this.videoThing.goToLocation(locationId);
        });

        this.videoThing.on("location_reached", id => {
          document
            .querySelectorAll('[data-location="' + id + '"]')
            .forEach(elem => {
              elem.parentNode.classList.add("uk-active");
            });
        });

        this.videoThing.on("location_left", id => {
          document.querySelectorAll("[data-location]").forEach(elem => {
            elem.parentNode.classList.remove("uk-active");
          });
        });
      });
    });
  }

  setCurrentLanguage() {
    const params = new URL(document.location).searchParams;
    this.currentLanguage = params.get("lang") || "de";
    console.info("LANGUAGE: " + this.currentLanguage);
  }
}

const digitalFair = new DigitalFair();
digitalFair.init();