import htmlToElement from "html-to-element";

export default class Meta {
  constructor(lang = "de") {
    this.lang = "de";
    this.fallbackLang = "de";
  }

  getAll() {
    return new Promise((resolve, reject) => {
      fetch("./static/content/meta.json")
        .then(response => {
          return response.json();
        })
        .then(locations => {
          resolve(locations[this.lang] || locations[this.fallbackLang]);
        });
    });
  }

  async getMenu() {
    var data = await this.getAll();
    return htmlToElement(
      require("./menu.twig")({
        meta: data
      })
    );
  }
}
