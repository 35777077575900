import htmlToElement from "html-to-element";
import UIkit from "uikit";
import "./index.scss";

export default class Content {
  constructor(lang) {
    this.lang = lang || "de";
    this.fallbackLang = "de";
  }

  getAll() {
    return new Promise((resolve, reject) => {
      fetch("./static/content/content.json")
        .then(response => {
          return response.json();
        })
        .then(json => {
          // current language only
          var res = {};
          for (let [key, value] of Object.entries(json)) {
            res[key] = value[this.lang] || value[this.fallbackLang];
          }
          resolve(res);
        })
        .catch(function() {
          console.warn("error");
        });
    });
  }

  getDataById(id) {
    return new Promise((resolve, reject) => {
      fetch("./static/content/content.json")
        .then(response => {
          return response.json();
        })
        .then(json => {
          var data = json[id][this.lang] || json[id][this.fallbackLang];
          resolve(data);
        })
        .catch(function() {
          console.warn("error");
        });
    });
  }

  async show(id) {
    var data = await this.getDataById(id);

    if (data.slides) {
      data.imageSlides = data.slides.filter(slide => {
        return slide.type == "image";
      });

      if (data.imageSlides.length == 0) {
        data.imageSlides.push({
          type: "image",
          src: "./static/content/placeholder.jpg",
          title: "",
          subtitle: "",
          html: ""
        });
      }
    } else {
      // keine slides für diese sprache
    }

    if (window.innerWidth <= 960) {
      this.elem = htmlToElement(require("./index.twig")(data));
    } else {
      this.elem = htmlToElement(require("./index-desktop.twig")(data));
    }

    UIkit.modal(this.elem).show();

    UIkit.util.on(this.elem, "hidden", () => {
      // delete elem from dom
      this.elem.parentNode.removeChild(this.elem);
    });
  }

  hide() {
    UIkit.modal(this.elem).hide();
  }

  async getMenu() {
    let data = await this.getAll(),
      elem;
    if (window.innerWidth <= 960) {
      elem = htmlToElement(require("./menu.twig")({
        content: data
      }));
    } else {
      elem = htmlToElement(require("./menu-desktop.twig")({
        content: data
      }));
    }
    return elem;
  }
}