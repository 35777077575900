import htmlToElement from "html-to-element";

export default class Locations {
  constructor(lang = "de") {
    this.lang = "de";
    this.fallbackLang = "de";
  }

  getAll() {
    return new Promise((resolve, reject) => {
      fetch("./static/content/locations.json")
        .then(response => {
          return response.json();
        })
        .then(locations => {
          // use translated titles ...f
          locations.map(location => {
            // ... for locations
            location.title = location.title[this.lang] ?
              location.title[this.lang] :
              location.title[this.fallbackLang];
            location.buttons.map(button => {
              // ... and buttons
              button.title = button.title[this.lang] ?
                button.title[this.lang] :
                button.title[this.fallbackLang];
            });
          });

          resolve(locations);
        });
    });
  }

  async getMenu() {
    let data = await this.getAll(),
      elem;
    console.log(data);
    if (window.innerWidth <= 960) {
      elem = htmlToElement(require("./menu.twig")({
        content: data
      }));
    } else {
      elem = htmlToElement(require("./menu-desktop.twig")({
        content: data
      }));
    }
    return elem;
  }
}